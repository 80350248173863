<!--
### Adaptive button
 -->
<script lang="ts">
  import { interact } from "@hyperfocal/actions";
  import arrow from "lucide-static/icons/arrow-right.svg?raw";
  import type {
    HTMLAnchorAttributes,
    HTMLButtonAttributes,
  } from "svelte/elements";

  type ButtonProps = {
    /** Visual theme of the button  */
    theme?: "primary" | "secondary" | "tertiary";
    /** Whether button is a danger button */
    danger?: boolean;
    /** Optional navigation arrow  */
    withArrow?: "forwards" | "backwards";
    /** Optional SVG icon */
    icon?: string;
    /** Whether button is busy */
    busy?: boolean;
    /** Whether to make it a fat button */
    fat?: boolean;
    /** Whether to make it a pill button */
    pill?: boolean;
    /** Optional href (makes button an anchor element) */
    href?: string;
    /** Convinience prop to make full width */
    fullwidth?: boolean;
    /** Optional callback on interaction */
    oninteract?(e: Event): void;
  } & HTMLButtonAttributes &
    HTMLAnchorAttributes;

  const {
    theme = "primary",
    danger = false,
    withArrow,
    icon,
    busy = false,
    fat = false,
    pill,
    href,
    fullwidth = false,
    disabled = false,
    type,
    children,
    oninteract,
    class: className = "",
    ...props
  }: ButtonProps = $props();
</script>

<style>
  .button {
    font-size: var(--button-font-size, var(--scale-00)) !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--size-2);
    padding: 10px var(--size-4);
    border-radius: var(--radius-sm);
    transition: all 250ms var(--ease-standard);
    cursor: pointer;
    &:active {
      filter: brightness(85%);
    }
    &.reversed {
      flex-direction: row-reverse;
    }
    &.fullwidth {
      display: flex;
      width: 100%;
    }
    &.responsive {
      @media not all and (--xs) {
        display: flex;
        width: 100%;
      }
    }
  }

  .content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
  }

  .icon :global(svg) {
    stroke: currentColor;
    height: var(--size-4);
    width: var(--size-4);
  }

  .arrow :global(svg) {
    width: var(--size-4);
    height: var(--size-4);
  }

  .reversed .arrow {
    transform: rotate(180deg);
  }

  /* Themes */
  .button.primary {
    background-color: var(--button-color, var(--color-accent));
    color: var(--button-text-color, white);
    &.danger {
      background-color: var(--color-error);
      &:hover,
      &:active {
        background-color: var(--color-error-700);
      }
    }
    &.disabled {
      opacity: 0.5;
    }
  }

  .button.secondary {
    border: 1px solid var(--button-color, currentColor);
    color: var(--button-color, currentColor);
    &.disabled {
      color: var(--color-text-tertiary);
      border-color: var(--color-border-secondary);
    }
    &.danger {
      color: var(--color-error);
      border-color: var(--color-error);
    }
  }

  .button.tertiary {
    color: var(--button-color, var(--color-accent));
    padding: 0 !important;
    &.disabled {
      color: var(--color-text-tertiary) !important;
    }
    &.danger {
      color: var(--color-error);
      &:hover {
        color: var(--color-error-700);
      }
    }
  }

  /* States */
  .button.fat {
    /* Matches form input height */
    padding: 15px var(--size-5);
  }

  .button.pill {
    border-radius: var(--radius-full);
  }

  .button.busy,
  .button.disabled {
    pointer-events: none;
    cursor: default;
  }

  .button.busy {
    opacity: 0.7;
  }
</style>

<svelte:element
  this={href ? "a" : "button"}
  class="button --text-ui {theme} {className}"
  {type}
  {href}
  use:interact={oninteract}
  class:busy
  class:fat
  class:pill
  class:danger
  class:fullwidth
  class:disabled
  class:reversed={withArrow === "backwards"}
  {...props}
>
  {#if icon}
    <span data-testid="button-icon" class="icon">{@html icon}</span>
  {/if}
  <div class="content">
    {@render children?.()}
  </div>
  {#if !!withArrow}
    <span data-testid="button-arrow" class="arrow">
      {@html arrow}
    </span>
  {/if}
</svelte:element>
